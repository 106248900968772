.container-why-us {
    flex-direction: column;

    z-index: 0;
}

.title-why-us {
    margin-bottom: 45px;
}

.container-why-us_grid-item {
    display: grid;
    justify-items: center;
    text-align: center;

    max-height: calc((100vw - 356px) / 2);
}

.container-why-us_grid-item img {
    max-height: 100px;
    filter: var(--muted-light-filter);
}

.secondary-text-why-us {
    margin: 5px 0;
}