.container-skeleton {
    flex-direction: column;

    z-index: 0;
}

.container_content {
    display: flex;
    justify-content: space-between;
}

.container-about_left-side img {
    max-width: 700px;

    filter: var(--muted-light-filter);
}

.container-about_right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
}

.container-about_right-side p {
    display: flex;
    align-items: center;
}

.container-about_right-side p img {
    max-width: 60px;
    margin-left: 40px;

    filter: var(--muted-light-filter);
}
