.container-main-page {
    padding-top: 50px;
    padding-bottom: 100px;
    max-height: calc(100vh - 150px);
    min-height: calc(100vh - 150px);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container-main-page_left-side {
    max-width: 1200px;
}

.container-main-page_left-side_text {
    margin-bottom: 60px;
}

.container-main-page_left-side_link {
    display: flex;
    align-items: center;

    font-size: 48px;
    color: var(--border-color);

    filter: var(--muted-light-filter);
    transition: 0.5s;

    cursor: pointer;
}

.container-main-page_left-side_link:hover {
    filter: var(--light-filter);
}

.container-main-page_right-side video {
    max-width: 25vw;
}

@media (max-width: 1300px) {
    .container-main-page_left-side_text {
        margin-bottom: 0;
    }
}

@media (max-width: 1000px) {
    .container-main-page_right-side {
        display: none;
    }

    .container-main-page {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
