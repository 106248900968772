input,
textarea {
    width: 100%;
    display: block;
}

@font-face {
    font-family: "Room";
    font-weight: normal;
    src: url("assets/fonts/fontThin.otf");
}

@font-face {
    font-family: "Room";
    font-weight: bold;
    src: url("assets/fonts/fontBold.otf");
}

:root {
    --light: 0 0 10px 2px #b0daf696;
    --light-text: 0 0 10px #b0daf6, 0 0 10px #b0daf6, 0 0 10px #b0daf6;
    --light-big-text: 0 0 5px #b0daf6, 0 0 5px #b0daf622;
    --muted-light-big-text: 0 0 5px #b0daf699;
    --border-color: #2da2ef;
    --text-color: #60bcf9;
    --light-color: #b0daf696;
    --light-filter: drop-shadow(0 0 3px #b0daf6dd)
        drop-shadow(0 0 10px #b0daf655);
    --muted-light-filter: drop-shadow(0 0 3px #b0daf699)
        drop-shadow(0 0 10px #b0daf644);
    --text-black-border: 1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,
        1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

a {
    color: inherit;
    text-decoration: none;
}

.header {
    box-sizing: border-box;
    background-color: black;
    color: white;
    font-family: Room;
    font-size: 25px;

    width: 100vw;
    height: 50px;
    padding: 0 100px;
    position: fixed;
    z-index: 1;
    transition-duration: 0.5s;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header_menu-links {
    transition-duration: 0.5s;
}

.header_menu-links:hover {
    text-shadow: var(--light-text);
}

.header_contact-button {
    font-size: 20px !important;
    color: white !important;
    border-color: #2da2efaa !important;
    box-shadow: 0 0 3px 1px #b0daf666;

    padding: 5px;
    height: 35px;

    transition-duration: 0.5s !important;
}

.header_contact-button:hover {
    box-shadow: var(--light);
    border-color: #2da2ef !important;
}

.component-container {
    max-width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    min-width: 80vw;
    max-width: 80vw;
    min-height: 100vh;
    max-height: 100vh;

    color: white !important;
    font-family: Room;
    font-weight: normal;

    display: flex;
}

.main-text {
    font-size: 60px;
    margin: 20px 0;
}

.secondary-text {
    font-size: 40px;
    margin: 20px 0;
}

.title {
    font-size: 65px;
    text-align: center;
    margin-top: 85px;
}

.text {
    font-size: 24px;
    margin: 0;
}

@media (max-width: 1300px) {
    .main-text {
        font-size: 50px;
    }
}
