.container-about {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-about {
    color: var(--text-color);
    text-shadow: 0 0 8px #b0daf6;
    margin-top: 0;
    margin-bottom: 60px;
}

.secondary-text-about {
    text-align: center;
    margin-top: 40px;
}
