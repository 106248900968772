.container-contacts {
    flex-direction: column;
    align-items: center;
}

.container-contacts .title {
    margin-bottom: 45px;
}

.container-contacts .grid {
    min-height: calc(100vh - 218px);
    min-height: calc(100vh - 218px);
}

.container-contacts .grid .contact-box {
    min-height: calc(100% - 70px);

    border: 2px solid #2da2ef;
    border-radius: 20px;
    box-shadow: var(--light);

    display: flex;
}

.container-contacts .grid .leave-email {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.container-contacts .grid .visit-us {
    flex-direction: row;
}

.container-contacts .grid .visit-us .left-side {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
}

.container-contacts .grid .visit-us .left-side .contact-text {
    margin-top: 25px;
    margin-bottom: 0;
}

.container-contacts .grid .visit-us .left-side .textblock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-contacts .grid .visit-us .left-side .text {
    max-width: 80%;
    color: var(--text-color);
    text-shadow: var(--light-big-text);
}

.container-contacts .grid .visit-us .right-side {
    width: 50%;
    overflow: hidden;

    border-left: 2px solid #2da2ef;
    border-radius: 0 18px 18px 0;
    background: white;
}

.container-contacts .grid .visit-us .right-side iframe {
    border: 0;
}

.container-contacts .grid .contact-box img {
    filter: var(--light-filter);
}

.container-contacts .grid .contact-box .left-side img {
    max-height: 85px;
}

.container-contacts .grid .contact-box .right-side img {
    max-height: 75px;
}

.container-contacts .grid .contact-box input {
    background: none;
    border: none;
    font-family: inherit;

    font-size: 32px;
    color: white;
    max-width: 80%;

    border-bottom: 2px solid var(--border-color);
    margin-bottom: 15px;
}

.container-contacts .grid .contact-box input:focus {
    outline: none;
    border: none;

    border-bottom: 2px solid #2da2ef;
}

.container-contacts .grid .contact-box button {
    background: none;
    color: white;
    font-family: inherit;
    border: none;

    max-width: fit-content;
    margin-bottom: 10px;

    font-size: 25px;
    border: 2px solid #2da2efaa;
    border-radius: 4px;

    transition-duration: 0.5s;
}

.container-contacts .grid .contact-box button:focus {
    background: none;
}

.container-contacts .grid .contact-box button:hover {
    cursor: pointer;
    box-shadow: var(--light);
    border-color: #2da2ef;
}

.container-contacts .grid .contact-text {
    margin-top: 0;

    font-size: 35px;
    text-align: center;
}

.container-contacts .grid .leave-email .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 10px;
}

.container-contacts .icons-box {
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
