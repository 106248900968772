.container-tms {
    flex-direction: column;
    align-items: center;
}

.container-tms .title {
    margin-bottom: 25px;
}

.container-tms .textbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container-tms .textbox p {
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
    font-size: 35px;
}

.container-tms .textbox .glowing-text {
    color: var(--text-color);
    text-shadow: 0 0 8px #b0daf6;
}
